"use client";
import { SessionProvider } from "@sajilni/auth/react";
import Toaster from "@/lib/sonner";
import posthog from "posthog-js";
import { PostHogProvider } from "posthog-js/react";

if (typeof window !== "undefined") {
  posthog.init(process.env.NEXT_PUBLIC_POSTHOG_KEY as string, {
    api_host: process.env.NEXT_PUBLIC_POSTHOG_HOST,
    person_profiles: "identified_only", // or 'always' to create profiles for anonymous users as well
    // Enable debug mode in development
    loaded: (posthog) => {
      if (process.env.NODE_ENV === "development") posthog.debug();
    },
  });
}
export function Providers({ children }: { children: React.ReactNode }) {
  return (
    <>
      {/* <PostHogProvider client={posthog}> */}
        <SessionProvider>
          <Toaster />
                {children}
        </SessionProvider>
      {/* </PostHogProvider> */}
    </>
  );
}
