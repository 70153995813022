import(/* webpackMode: "eager", webpackExports: ["Providers"] */ "/home/sajilni2/web/sajilni2.0/apps/sajilni2.0/src/app/providers.tsx");
;
import(/* webpackMode: "eager" */ "/home/sajilni2/web/sajilni2.0/node_modules/next/font/google/target.css?{\"path\":\"src/styles/fonts.ts\",\"import\":\"Inter\",\"arguments\":[{\"variable\":\"--font-inter\",\"subsets\":[\"latin\"]}],\"variableName\":\"inter\"}");
;
import(/* webpackMode: "eager" */ "/home/sajilni2/web/sajilni2.0/node_modules/next/font/google/target.css?{\"path\":\"src/styles/fonts.ts\",\"import\":\"Roboto\",\"arguments\":[{\"variable\":\"--font-roboto\",\"subsets\":[\"latin\"],\"weight\":[\"100\",\"300\",\"400\",\"500\",\"700\",\"900\"]}],\"variableName\":\"roboto\"}");
;
import(/* webpackMode: "eager" */ "/home/sajilni2/web/sajilni2.0/node_modules/next/font/google/target.css?{\"path\":\"src/styles/fonts.ts\",\"import\":\"Roboto\",\"arguments\":[{\"variable\":\"--white-label-font\",\"subsets\":[\"latin\"],\"weight\":[\"300\",\"400\",\"500\",\"700\",\"900\"]}],\"variableName\":\"robotoWhiteLabel\"}");
;
import(/* webpackMode: "eager" */ "/home/sajilni2/web/sajilni2.0/node_modules/next/font/google/target.css?{\"path\":\"src/styles/fonts.ts\",\"import\":\"Anton\",\"arguments\":[{\"variable\":\"--white-label-font\",\"subsets\":[\"latin\"],\"weight\":\"400\",\"display\":\"swap\"}],\"variableName\":\"anton\"}");
;
import(/* webpackMode: "eager" */ "/home/sajilni2/web/sajilni2.0/node_modules/next/font/google/target.css?{\"path\":\"src/styles/fonts.ts\",\"import\":\"Lobster\",\"arguments\":[{\"variable\":\"--white-label-font\",\"subsets\":[\"latin\"],\"weight\":\"400\",\"display\":\"swap\"}],\"variableName\":\"lobster\"}");
;
import(/* webpackMode: "eager" */ "/home/sajilni2/web/sajilni2.0/apps/sajilni2.0/src/styles/globals.css");
;
import(/* webpackMode: "eager" */ "/home/sajilni2/web/sajilni2.0/packages/ui/dist/index.css");
